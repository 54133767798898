<template>
  <div>
     <div class="app-content content " style="margin: 0; padding: 30px;">
        <div class="content-overlay"></div>
        <!-- <div class="header-navbar-shadow"></div> -->
        <div class="content-wrapper">
            <div class="content-header row">
            </div>
            <div class="content-body">
                <div class="auth-wrapper auth-cover">
                    <div class="auth-inner row m-0">
                        <!-- Left Text-->
                        <div class="d-none d-lg-flex col-lg-6 align-items-center p-5">
                            <div class="w-100 d-lg-flex align-items-center justify-content-center px-5"><img class="img-fluid" src="/app-assets/images/pages/reset-password-v2.svg" alt="Register V2" /></div>
                        </div>
                        <!-- /Left Text-->
                        <!-- Reset password-->
                        <div class="d-flex col-lg-6 align-items-center auth-bg px-2 p-lg-5">
                            <div class="col-12 col-sm-8 col-md-6 col-lg-12 px-xl-2 mx-auto">
                                 <div style="margin-left: -15px;">
                                  <a class="nav-link py-lg-2" href="/">
                                      <img src="/app-assets/images/brand-logo.png" style="height: 45px;">
                                   </a>
                                </div>
                                <h2 class="card-title fw-bold mb-1">Reset Password 🔒</h2>
                                <p class="card-text mb-2">Your new password must be different from previously used passwords</p>
                                <form class="auth-reset-password-form mt-2" action="auth-login-cover.html" method="POST" @submit.prevent="resetPassword()">
                                    <div class="mb-1">
                                        <div class="d-flex justify-content-between">
                                            <label class="form-label" for="reset-password-new">New Password</label>
                                        </div>
                                        <div class="input-group input-group-merge form-password-toggle">
                                            <input class="form-control form-control-merge" id="reset-password-new" v-model="formData.password" type="password" name="reset-password-new" placeholder="············" aria-describedby="reset-password-new" autofocus="" tabindex="1" /><span class="input-group-text cursor-pointer"><i data-feather="eye"></i></span>
                                        </div>
                                    </div>
                                    <div class="mb-1">
                                        <div class="d-flex justify-content-between">
                                            <label class="form-label" for="reset-password-confirm">Confirm Password</label>
                                        </div>
                                        <div class="input-group input-group-merge form-password-toggle">
                                            <input class="form-control form-control-merge" id="reset-password-confirm" v-model="formData.password_confirmation" type="password" name="reset-password-confirm" placeholder="············" aria-describedby="reset-password-confirm" tabindex="2" /><span class="input-group-text cursor-pointer"><i data-feather="eye"></i></span>
                                        </div>
                                    </div>
                                    <button class="btn btn-primary w-100" tabindex="3">Set New Password</button>
                                </form>
                                <p class="text-center mt-2"><router-link to="/login"><i data-feather="chevron-left"></i> Back to login</router-link></p>
                            </div>
                        </div>
                        <!-- /Reset password-->
                    </div>
                </div>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
import handleResetPassword from '@/services/modules/passwordReset'
import { inject } from 'vue'
export default {
  name: 'ResetPassword',
  components: {
    
  },
  data:()=>({
      formData:{
          token: null,
          email: null,
          password: null,
          password_confirmation: null,
      }
  }),
  methods:{
      async resetPassword() {
      try {
        this.loading = true
        let res = await this.setResetPassword(this.formData);
        if(!res.status) {
          this.showError(res.message)
        }
        if(res.status) {
          this.showSuccess(res.message)
           window.location.href = '/login';
           this.$router.push({name: 'Login'})
        }
      } catch (err) {
        if(!err.response) {
          this.showError('Something is wrong. Check your connectivity!!')
        }
        if(err.response) {
          this.showError(err.response.message)
        }
      } finally {
         this.loading = false
      }
    },
 
  },
  computed:{
      getEmailToken(){
          this.formData.token=this.$route.query.token,
          this.formData.email=this.$route.query.email
        //   alert(this.formData.token)
      }
  },
  mounted(){
      this.getEmailToken()
  },
      setup() {
    const showError   =  inject('showError');
    const showSuccess =  inject('showSuccess');
    // const store = useStore()  

    const { 
      // loginUser, 
      loading,
      setResetPassword
      // setUserInLocalStorage,
      // setTokenInLocalStorage
    } = handleResetPassword()

    return {
      // setUser: () => store.commit('user/setUser'),
      // user: computed(() => store.state.user.user),
      // loginUser,
      loading,
      showError,
      showSuccess,
      setResetPassword
      // setUserInLocalStorage,
      // setTokenInLocalStorage
    }
  }
}
</script>

<style scoped>
  .contnet{
    margin: 0 !important;
  }
</style>
