import Network from '@/services/network'
import { ref } from 'vue'

export default function handleResetPassword() {
    const network = new Network;
    const loading = ref(false)

    /* const fetchSidebar = (id=1) => {
      return network.api('get', '/get/sidebar?module_id='+id);
    } */

    /* const fetchCompanyInfo = (id) => {
      return network.api('get', '/company/'+id);
    } */

    /* const fetchCompanyModules = (id) => {
      return network.api('get', '/get/modules/'+id);
    } */

    const setResetPassword = (data) => {
      return network.api('post', '/reset-password', data);
    }

    return {
        // fetchSidebar,
        // fetchCompanyInfo,
        // fetchCompanyModules,
        // createCompany,
        setResetPassword,
        loading
    }
}